var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "filterDisplay",
          staticClass: "d-flex flex-wrap justify-space-between align-center"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex px-5 align-center" },
            [
              _vm.onMapPage
                ? _c("TableViewDropdown", {
                    staticClass: "pt-4",
                    attrs: { currentSelectedTable: this.newSelectedTable },
                    on: {
                      "selected-table": function($event) {
                        return _vm.$emit("selected-table", $event)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "px-1",
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      _vm.showFormSubmissionsFilterDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                  _vm._v(" Filter ")
                ],
                1
              ),
              _c("FormSubmissionsFilterChoicesDisplay", {
                attrs: {
                  filterChoices: _vm.filterChoices,
                  users: _vm.users,
                  siteInfos: _vm.siteInfos,
                  mapServices: _vm.mapServices,
                  formDefinitions: _vm.formDefinitions,
                  formFieldFilterChoices: _vm.formFieldFilterChoices
                },
                on: {
                  "remove-filter": _vm.removeFilter,
                  "remove-form-field-filter": _vm.removeFormFieldFilter
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex px-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-1",
                  attrs: {
                    text: "",
                    color: "#3F51B5",
                    disabled: _vm.downloadingFormResults
                  },
                  on: { click: _vm.downloadFormResults }
                },
                [
                  _vm.downloadingFormResults
                    ? _c("v-progress-circular", {
                        staticClass: "mr-1",
                        attrs: { indeterminate: "", size: 15 }
                      })
                    : _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiDownload))]),
                  _vm._v(" Download ")
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "px-1 ml-3",
                                  attrs: { text: "", color: "#3F51B5" }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { dark: "" } },
                                [_vm._v(" " + _vm._s(_vm.mdiPlusCircle) + " ")]
                              ),
                              _vm._v(" Field ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        _vm._l(_vm.nonEmptyHeaders, function(h) {
                          return _c("v-checkbox", {
                            key: h.value,
                            staticClass: "py-0 my-0",
                            attrs: { label: h.text },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.headersEnabled[h.value],
                              callback: function($$v) {
                                _vm.$set(_vm.headersEnabled, h.value, $$v)
                              },
                              expression: "headersEnabled[h.value]"
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("v-data-table", {
        staticClass: "pa-0 ma-0 cursor-pointer",
        attrs: {
          headers: _vm.filteredHeaders,
          items: _vm.mappedFormSubmissions,
          "item-key": "formResultId",
          "hide-default-footer": "",
          "disable-pagination": "",
          height: _vm.tableHeight,
          "fixed-header": "",
          loading: _vm.loading
        },
        on: { "click:row": _vm.onRowClick },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function() {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.isOnline
                          ? "No data available"
                          : "This device is offline. <u>Reload</u> the page when you are back online to load the Form Submissions"
                      )
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.menu",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiDotsVertical) + " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "px-0 mx-0" },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  return _vm.viewForm(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiNotePlusOutline))
                              ]),
                              _vm._v(" View Form ")
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  return _vm.downloadPdf(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiBookOpenOutline))
                              ]),
                              _vm._v(" Download PDF ")
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  return _vm.viewUtiliBots(item)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/UtiliBotIconGray.svg"),
                                  height: "24",
                                  width: "24"
                                }
                              }),
                              _vm._v(" View UtiliBots ")
                            ]
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  _vm.selectedFormResult = item
                                  _vm.showFormInfoDialog = true
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiInformation))
                              ]),
                              _vm._v(" Form Submission ID ")
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  _vm.showDeleteFormDialog = true
                                  _vm.selectedFormResult = item
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v(_vm._s(_vm.mdiDelete))]),
                              _vm._v(" Delete Form ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.updatedOn",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatLocalFullDate")(item.updatedOn)) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.formTitle",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm.getDisplayFormFieldTitle(item)) + " ")
                ]
              }
            },
            {
              key: "item.refField",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getSiteName(item)) + " ")]
              }
            },
            {
              key: "item.overallTasksStatus",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.overallTasksStatus ===
                  _vm.FORM_RESULT_STATUS.COMPLETED_ERROR
                    ? _c("v-icon", { attrs: { color: "#FFAE42" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiAlert) + " ")
                      ])
                    : item.overallTasksStatus ===
                      _vm.FORM_RESULT_STATUS.PROCESSING
                    ? _c("v-icon", { attrs: { color: "#00A3FF" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiAutorenew) + " ")
                      ])
                    : item.overallTasksStatus ===
                      _vm.FORM_RESULT_STATUS.COMPLETED_SUCCESS
                    ? _c("v-icon", { attrs: { color: "green" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiCheck) + " ")
                      ])
                    : item.overallTasksStatus ===
                        _vm.FORM_RESULT_STATUS.DEAD_LETTER_QUEUE ||
                      item.overallTasksStatus === _vm.FORM_RESULT_STATUS.ERROR
                    ? _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiCloseCircleOutline) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.mapServiceId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getMapServiceName(item)) + " ")]
              }
            },
            {
              key: "item.featureId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.featureId) + " ")]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { ref: "bottomBar", staticClass: "d-flex justify-center py-2" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "#3F51B5", disabled: _vm.allLoaded },
              on: {
                click: function($event) {
                  return _vm.getMoreFormSubmissions()
                }
              }
            },
            [_vm._v(" Load More ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            persistent: "",
            fullscreen: _vm.isFullScreen
          },
          model: {
            value: _vm.showEditFormDialog,
            callback: function($$v) {
              _vm.showEditFormDialog = $$v
            },
            expression: "showEditFormDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.showEditFormDialog
                ? _c("DynamicForm", {
                    attrs: {
                      formDefinition: _vm.selectedFormResult,
                      selectedMapServiceId: _vm.selectedFormResult.mapServiceId,
                      existingFormResultIdMap: _vm.existingFormResultIdMap,
                      globalId: _vm.selectedFormResult.featureId,
                      objectId: _vm.selectedFeatureGlobalId,
                      canEdit: false,
                      alreadySubmittedFinalOnline:
                        _vm.alreadySubmittedFinalOnline
                    },
                    on: {
                      "ticket-edit-form-close-button-click": function($event) {
                        _vm.showEditFormDialog = false
                      },
                      "ticket-edit-form-close": function($event) {
                        _vm.showEditFormDialog = false
                      },
                      "ticket-edit-form-submitted": function($event) {
                        _vm.showEditFormDialog = false
                      },
                      input: function($event) {
                        _vm.selectedFormResult = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.showFormSubmissionsFilterDialog
        ? _c("FormSubmissionsFilterDialog", {
            attrs: {
              showFormSubmissionsFilterDialog:
                _vm.showFormSubmissionsFilterDialog,
              users: _vm.users,
              mapServices: _vm.mapServices,
              savedFilterChoices: _vm.filterChoices,
              savedFormFieldFilterChoices: _vm.formFieldFilterChoices,
              formDefinitions: _vm.formDefinitions,
              siteInfos: _vm.siteInfos
            },
            on: {
              "form-submission-filter-dialog-close": function($event) {
                _vm.showFormSubmissionsFilterDialog = false
              },
              "update-filter": _vm.onUpdateFilter
            }
          })
        : _vm._e(),
      _vm.showFormInfoDialog
        ? _c("FormInfoDialog", {
            attrs: {
              formResultId: _vm.selectedFormResult.formResultId,
              showFormInfoDialog: _vm.showFormInfoDialog
            },
            on: {
              "form-info-dialog-close": function($event) {
                _vm.showFormInfoDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showUtilibotDialog
        ? _c("UtilibotDialog", {
            attrs: {
              showUtilibotDialog: _vm.showUtilibotDialog,
              formResultId: _vm.selectedFormResult.formResultId
            },
            on: {
              "utilibot-dialog-close": function($event) {
                _vm.showUtilibotDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showDeleteFormDialog
        ? _c("DeleteFormDialog", {
            attrs: { showDeleteFormDialog: _vm.showDeleteFormDialog },
            on: {
              "cancel-delete-form": function($event) {
                _vm.showDeleteFormDialog = false
              },
              "delete-form": _vm.deleteForm
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }