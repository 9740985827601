<template>
  <div class="d-flex flex-wrap gap">
    <v-chip
      v-for="f of displayFilterChoices"
      :key="f.selectedField"
      close
      @click:close="$emit('remove-filter', f.selectedField)"
    >
      <div class="chip overflow-hidden text-truncate my-0 py-0">
        {{ f.selectedField | formSubmissionFields }}
        <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
        {{ getDisplayMatchValue(f.selectedField, f.selectedMatchValue) }}
      </div>
    </v-chip>

    <v-chip
      v-if="refFieldFilterChoice"
      close
      @click:close="$emit('remove-filter', refFieldFilterChoice.selectedField)"
    >
      <div class="chip overflow-hidden text-truncate my-0 py-0">
        Reference Field = {{ refFieldFilterChoice.selectedMatchValue }}
      </div>
    </v-chip>

    <v-chip
      v-for="f of formFieldFilterChoices"
      :key="f.selectedFormField"
      close
      @click:close="$emit('remove-form-field-filter', f)"
    >
      <div class="chip overflow-hidden text-truncate my-0 py-0">
        {{ getDisplayFormFieldTitle(f.selectedFormField) }}
        <MatchIcon :value="f.selectedFormFieldMatchChoice" class="mx-2" />
        {{
          getDisplayFormFieldMatchValue(
            f.selectedFormField,
            f.selectedFormFieldMatchValue
          )
        }}
      </div>
    </v-chip>
  </div>
</template>

<script>
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";

export default {
  name: "FormSubmissionsFilterChoicesDisplay",
  props: {
    filterChoices: Array,
    users: Array,
    siteInfos: Array,
    mapServices: Array,
    formFieldFilterChoices: Array,
    formDefinitions: Array,
  },
  components: {
    MatchIcon,
  },
  computed: {
    refFieldFilterChoice() {
      return this.filterChoices.find((f) => f.selectedField === "site");
    },
    displayFilterChoices() {
      return this.filterChoices.filter((f) => {
        return !["mapServiceId", "objectIds", "site"].includes(f.selectedField);
      });
    },
  },
  methods: {
    getDisplayFormFieldTitle(selectedFormFieldId) {
      const formDefId = this.filterChoices?.find(
        (f) => f.selectedField === "formTitle"
      )?.selectedMatchValue;
      const selectedFormDefinition = this.formDefinitions.find(
        (f) => f.form_definition_id === formDefId
      );

      return selectedFormDefinition?.form?.sections
        ?.map((s) => s.items)
        ?.flat()
        ?.find((f) => +f.id === +selectedFormFieldId)?.question?.label;
    },
    getDisplayFormFieldMatchValue(
      selectedFormFieldId,
      selectedFormFieldMatchValue
    ) {
      const formDefId = this.filterChoices?.find(
        (f) => f.selectedField === "formTitle"
      )?.selectedMatchValue;
      const selectedFormDefinition = this.formDefinitions.find(
        (f) => f.form_definition_id === formDefId
      );
      const item = selectedFormDefinition?.form?.sections
        ?.map((s) => s.items)
        ?.flat()
        ?.find((f) => +f.id === +selectedFormFieldId);

      if (item?.question?.type === "SINGLE_SELECT") {
        return item?.question?.options?.find(
          (it) => it.value === selectedFormFieldMatchValue
        )?.label;
      } else if (item?.question?.type === "DATE") {
        if (Array.isArray(selectedFormFieldMatchValue)) {
          return selectedFormFieldMatchValue.join(" - ");
        }
        return selectedFormFieldMatchValue;
      }
      return item?.value ?? selectedFormFieldMatchValue;
    },
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "submittedBy") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        if (user) {
          const { f_name: fName, l_name: lName } = user;
          return `${fName} ${lName}`;
        } else {
          return selectedMatchValue;
        }
      } else if (selectedField === "formTitle") {
        const selectedFormDefinition = this.formDefinitions.find(
          (f) => f.form_definition_id === selectedMatchValue
        );
        return selectedFormDefinition?.form?.formDescription?.title;
      }
      return selectedMatchValue;
    },
  },
};
</script>
