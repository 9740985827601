<template>
  <div>
    <div
      class="d-flex flex-wrap justify-space-between align-center"
      ref="filterDisplay"
    >
      <div class="d-flex px-5 align-center">
        <TableViewDropdown
          class="pt-4"
          v-if="onMapPage"
          @selected-table="$emit('selected-table', $event)"
          :currentSelectedTable="this.newSelectedTable"
        />

        <v-btn
          text
          color="#3F51B5"
          class="px-1"
          @click="showFormSubmissionsFilterDialog = true"
        >
          <v-icon> {{ mdiFilter }}</v-icon>
          Filter
        </v-btn>

        <FormSubmissionsFilterChoicesDisplay
          :filterChoices="filterChoices"
          :users="users"
          :siteInfos="siteInfos"
          :mapServices="mapServices"
          :formDefinitions="formDefinitions"
          :formFieldFilterChoices="formFieldFilterChoices"
          @remove-filter="removeFilter"
          @remove-form-field-filter="removeFormFieldFilter"
        />
      </div>

      <div class="d-flex px-5">
        <v-btn
          text
          color="#3F51B5"
          @click="downloadFormResults"
          class="px-1"
          :disabled="downloadingFormResults"
        >
          <v-progress-circular
            indeterminate
            :size="15"
            v-if="downloadingFormResults"
            class="mr-1"
          ></v-progress-circular>
          <v-icon v-else> {{ mdiDownload }}</v-icon>
          Download
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              color="#3F51B5"
              class="px-1 ml-3"
            >
              <v-icon dark class="mr-1">
                {{ mdiPlusCircle }}
              </v-icon>
              Field
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="h of nonEmptyHeaders"
                :key="h.value"
                @click.stop
                v-model="headersEnabled[h.value]"
                :label="h.text"
                class="py-0 my-0"
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>

    <v-data-table
      :headers="filteredHeaders"
      :items="mappedFormSubmissions"
      item-key="formResultId"
      class="pa-0 ma-0 cursor-pointer"
      @click:row="onRowClick"
      hide-default-footer
      disable-pagination
      :height="tableHeight"
      fixed-header
      :loading="loading"
    >
      <template v-slot:no-data>
        <div
          v-html="
            isOnline
              ? 'No data available'
              : 'This device is offline. <u>Reload</u> the page when you are back online to load the Form Submissions'
          "
        ></div>
      </template>

      <template v-slot:[`item.menu`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
              <v-icon dark>
                {{ mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list class="px-0 mx-0">
            <v-list-item class="mx-0 gap" @click="viewForm(item)">
              <v-icon>{{ mdiNotePlusOutline }}</v-icon>
              View Form
            </v-list-item>
            <v-list-item class="mx-0 gap" @click="downloadPdf(item)">
              <v-icon>{{ mdiBookOpenOutline }}</v-icon>
              Download PDF
            </v-list-item>
            <v-list-item class="mx-0 gap" @click="viewUtiliBots(item)">
              <img src="@/assets/UtiliBotIconGray.svg" height="24" width="24" />
              View UtiliBots
            </v-list-item>
            <v-list-item
              class="mx-0 gap"
              @click="
                selectedFormResult = item;
                showFormInfoDialog = true;
              "
            >
              <v-icon>{{ mdiInformation }}</v-icon>
              Form Submission ID
            </v-list-item>
            <v-list-item
              class="mx-0 gap"
              @click="
                showDeleteFormDialog = true;
                selectedFormResult = item;
              "
            >
              <v-icon>{{ mdiDelete }}</v-icon> Delete Form
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.updatedOn`]="{ item }">
        {{ item.updatedOn | formatLocalFullDate }}
      </template>

      <template v-slot:[`item.formTitle`]="{ item }">
        {{ getDisplayFormFieldTitle(item) }}
      </template>

      <template v-slot:[`item.refField`]="{ item }">
        {{ getSiteName(item) }}
      </template>

      <template v-slot:[`item.overallTasksStatus`]="{ item }">
        <v-icon
          v-if="item.overallTasksStatus === FORM_RESULT_STATUS.COMPLETED_ERROR"
          color="#FFAE42"
        >
          {{ mdiAlert }}
        </v-icon>
        <v-icon
          v-else-if="item.overallTasksStatus === FORM_RESULT_STATUS.PROCESSING"
          color="#00A3FF"
        >
          {{ mdiAutorenew }}
        </v-icon>
        <v-icon
          v-else-if="
            item.overallTasksStatus === FORM_RESULT_STATUS.COMPLETED_SUCCESS
          "
          color="green"
        >
          {{ mdiCheck }}
        </v-icon>
        <v-icon
          v-else-if="
            item.overallTasksStatus === FORM_RESULT_STATUS.DEAD_LETTER_QUEUE ||
            item.overallTasksStatus === FORM_RESULT_STATUS.ERROR
          "
          color="red"
        >
          {{ mdiCloseCircleOutline }}
        </v-icon>
      </template>

      <template v-slot:[`item.mapServiceId`]="{ item }">
        {{ getMapServiceName(item) }}
      </template>

      <template v-slot:[`item.featureId`]="{ item }">
        {{ item.featureId }}
      </template>
    </v-data-table>

    <div class="d-flex justify-center py-2" ref="bottomBar">
      <v-btn
        text
        color="#3F51B5"
        :disabled="allLoaded"
        @click="getMoreFormSubmissions()"
      >
        Load More
      </v-btn>
    </div>

    <v-dialog
      v-model="showEditFormDialog"
      max-width="600px"
      persistent
      :fullscreen="isFullScreen"
    >
      <v-card>
        <DynamicForm
          :formDefinition="selectedFormResult"
          :selectedMapServiceId="selectedFormResult.mapServiceId"
          :existingFormResultIdMap="existingFormResultIdMap"
          :globalId="selectedFormResult.featureId"
          :objectId="selectedFeatureGlobalId"
          :canEdit="false"
          :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
          @ticket-edit-form-close-button-click="showEditFormDialog = false"
          @ticket-edit-form-close="showEditFormDialog = false"
          @ticket-edit-form-submitted="showEditFormDialog = false"
          @input="selectedFormResult = $event"
          v-if="showEditFormDialog"
        />
      </v-card>
    </v-dialog>

    <FormSubmissionsFilterDialog
      v-if="showFormSubmissionsFilterDialog"
      :showFormSubmissionsFilterDialog="showFormSubmissionsFilterDialog"
      :users="users"
      :mapServices="mapServices"
      :savedFilterChoices="filterChoices"
      :savedFormFieldFilterChoices="formFieldFilterChoices"
      :formDefinitions="formDefinitions"
      :siteInfos="siteInfos"
      @form-submission-filter-dialog-close="
        showFormSubmissionsFilterDialog = false
      "
      @update-filter="onUpdateFilter"
    />

    <FormInfoDialog
      v-if="showFormInfoDialog"
      :formResultId="selectedFormResult.formResultId"
      :showFormInfoDialog="showFormInfoDialog"
      @form-info-dialog-close="showFormInfoDialog = false"
    />

    <UtilibotDialog
      v-if="showUtilibotDialog"
      :showUtilibotDialog="showUtilibotDialog"
      :formResultId="selectedFormResult.formResultId"
      @utilibot-dialog-close="showUtilibotDialog = false"
    />

    <DeleteFormDialog
      v-if="showDeleteFormDialog"
      :showDeleteFormDialog="showDeleteFormDialog"
      @cancel-delete-form="showDeleteFormDialog = false"
      @delete-form="deleteForm"
    />
  </div>
</template>

<script>
import {
  mdiFilter,
  mdiPlusCircle,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiClose,
  mdiNotePlusOutline,
  mdiBookOpenOutline,
  mdiCloseCircleOutline,
  mdiCheck,
  mdiAutorenew,
  mdiAlert,
  mdiInformation,
  mdiDownload,
} from "@mdi/js";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import { db } from "@/mixins/utilisync-db";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import signOutMixin from "@/mixins/signOutMixin";
import FORM_RESULT_STATUS from "@/constants/formResultStatus";
import FormSubmissionsFilterDialog from "@/components/form-submissions/FormSubmissionsFilterDialog";
import FormSubmissionsFilterChoicesDisplay from "@/components/form-submissions/FormSubmissionsFilterChoicesDisplay";
import getUtiliSyncLayerFeatureSiteName from "@/mixins/getUtiliSyncLayerFeatureSiteName";
import FormInfoDialog from "@/components/tickets/shared/FormInfoDialog";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";
import DeleteFormDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/DeleteFormDialog";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import TableViewDropdown from "@/components/mapView/TableViewDropdown";
import axios from "axios";
import moment from "moment";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";

const APIURL = process.env.VUE_APP_API_URL;
const ITEMS_PER_PAGE = 50;

const headers = [
  {
    text: "Reference Field",
    align: "start",
    value: "refField",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Form",
    align: "start",
    value: "formTitle",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Submitted By",
    align: "start",
    value: "submittedBy",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Last Updated",
    align: "start",
    value: "updatedOn",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Utilibot Status",
    value: "overallTasksStatus",
    sortable: true,
    minWidth: "75px",
    width: "120px",
    align: "start",
  },
  {
    text: "Layer",
    align: "start",
    value: "mapServiceId",
    sortable: true,
    minWidth: "150px",
    width: "150px",
  },
  {
    text: "Object ID",
    align: "start",
    value: "featureId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "",
    align: "start",
    value: "menu",
    sortable: false,
    minWidth: "120px",
    width: "120px",
  },
];

export default {
  name: "MapViewFormSubmissionView",
  components: {
    DynamicForm,
    FormSubmissionsFilterDialog,
    FormSubmissionsFilterChoicesDisplay,
    FormInfoDialog,
    UtilibotDialog,
    DeleteFormDialog,
    TableViewDropdown,
  },
  mixins: [
    gisInfoMixin,
    downloadDataMixin,
    bulkDownloadDataMixin,
    signOutMixin,
    networkStatusMixin,
    fullScreenCheckMixin,
  ],
  data() {
    return {
      mdiFilter,
      mdiPlusCircle,
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiClose,
      mdiNotePlusOutline,
      mdiBookOpenOutline,
      mdiCloseCircleOutline,
      mdiCheck,
      mdiAutorenew,
      mdiAlert,
      mdiInformation,
      mdiDownload,
      formSubmissions: [],
      headers,
      gisInfos: [],
      headersEnabled: {
        refField: true,
        formTitle: true,
        submittedBy: true,
        updatedOn: true,
        overallTasksStatus: true,
        menu: true,
        mapServiceId: false,
        featureId: false,
      },
      formDefinitions: [],
      mapServices: [],
      users: [],
      gisInfoTab: undefined,
      showExpansionPanelDialog: false,
      selectedGisInfoIndex: 0,
      selectedGisInfo: {},
      featureItemFields: [],
      featureItemAttributes: {},
      selectedFormResult: {},
      showEditFormDialog: false,
      showFormSubmissionFilterDialog: false,
      showFormIdDialog: false,
      filterChoices: [],
      showUnableToObtainGisAttributesDialog: false,
      siteInfos: [],
      showConfirmDeleteDialog: false,
      FORM_RESULT_STATUS,
      selectedMapServiceId: undefined,
      selectedFeatureGlobalId: undefined,
      map: undefined,
      view: undefined,
      showFormSubmissionsFilterDialog: false,
      allLoaded: false,
      page: 1,
      existingFormResultIdMap: {},
      showFormInfoDialog: false,
      showUtilibotDialog: false,
      showDeleteFormDialog: false,
      formFieldFilterChoices: [],
      formSubmissionData: [],
      downloadTimer: undefined,
      downloadingFormResults: false,
      tableHeight: 0,
      loading: false,
    };
  },
  props: {
    newSelectedTable: String,
    onMapPage: Boolean,
    bottomPaneHeight: Number,
    progressBarHeight: Number,
    inMapView: Boolean,
  },
  computed: {
    alreadySubmittedFinalOnline() {
      return this.selectedFormResult?.form?.status === "SUBMITTED_FINAL";
    },
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];

          return [this.findAliasByName(this.featureItemFields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    notUtiliSyncLayer() {
      const mapService = this.mapServices.find(
        (m) => m.map_service_id === this.selectedTask?.mapServiceId
      );
      return mapService.service_type !== "U";
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    mappedFormSubmissions() {
      return this.formSubmissions.map((f) => {
        const {
          f_name: fName,
          feature_id: featureId,
          form_result_id: formResultId,
          form_title: formTitle,
          l_name: lName,
          map_service_id: mapServiceId,
          overall_tasks_status: overallTasksStatus,
          updated_on: updatedOn,
        } = f;
        return {
          submittedBy: `${fName} ${lName}`,
          featureId,
          formResultId,
          formTitle,
          mapServiceId,
          overallTasksStatus,
          updatedOn,
        };
      });
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    selectedLayerType() {
      return this.mapServices.find(
        (m) => m.map_service_id === this.selectedTask?.mapServiceId
      )?.service_type;
    },
  },
  watch: {
    filterChoices: {
      deep: true,
      handler() {
        this.onResize();
      },
    },
    progressBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    topBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    formSubmissions: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (
          Array.isArray(newVal) &&
          Array.isArray(oldVal) &&
          newVal.length > oldVal.length &&
          newVal.length > 0
        ) {
          this.getFormDataByFormResultIds();
        }
      },
    },
    bottomPaneHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    isOnline(val) {
      this.formSubmissions = [];
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    onResize() {
      if (this.inMapView) {
        const height =
          this.bottomPaneHeight -
          this.progressBarHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          20;
        this.tableHeight = Math.max(height, 135);
      } else {
        const height =
          window.innerHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          (this.topBarHeight ?? 56) -
          30;
        this.tableHeight = Math.max(height, 200);
      }
    },
    async downloadFormResults() {
      this.downloadingFormResults = true;
      clearInterval(this.downloadTimer);
      this.downloadTimer = undefined;
      const { filterChoices, formFieldFilterChoices } = this;
      const newFilterChoices = [
        ...filterChoices.filter(
          (c) => !["ticketStatus"].includes(c.selectedField)
        ),
      ];
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/form_results/report/utilibot_summary/all/download`,
        {
          skip: 0,
          filters: newFilterChoices,
          formFieldFilterChoices,
          showNonLocateRequestsOnly: true,
          esriToken: localStorage.getItem("esri_token"),
        }
      );
      const { file_id: fileId } = results;
      this.downloadTimer = setInterval(async () => {
        try {
          const { data } = await axiosWithRegularAuth.get(
            `${APIURL}/files/big_job/${fileId}`,
            {
              responseType: "blob",
            }
          );
          const dataURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.download = `form_results.csv`;
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          link.remove();
          clearInterval(this.downloadTimer);
          this.downloadTimer = undefined;
          this.downloadingFormResults = false;
        } catch (error) {
          console.log(error);
        }
      }, 1000);
    },
    getDisplayFormFieldTitle(formResult) {
      return this.formSubmissionData?.find(
        (f) => f.form_result_id === formResult?.formResultId
      )?.form_title;
    },
    async getFormDataByFormResultIds() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/form_results/report/utilibot_summary/all/form_data`,
        {
          formResultIds: this.formSubmissions.map((f) => f.form_result_id),
        }
      );
      this.formSubmissionData = results;
    },
    onUpdateFilter({ filterChoices, formFieldFilterChoices }) {
      this.filterChoices = filterChoices;
      this.formFieldFilterChoices = formFieldFilterChoices;
      this.showFormSubmissionsFilterDialog = false;
      this.page = 1;
      this.getFormSubmissions();
    },
    getMapServiceName(formResult) {
      return this.mapServices.find(
        (m) => m.map_service_id === formResult?.mapServiceId
      )?.service_name;
    },
    async deleteForm() {
      this.showDeleteFormDialog = false;
      await axiosWithRegularAuth.delete(
        `${APIURL}/form_results/${this.selectedFormResult.formResultId}`
      );
      this.page = 1;
      this.getFormSubmissions();
    },
    async removeFormFieldFilter() {
      this.formFieldFilterChoices = [];
      await this.$nextTick();
      this.page = 1;
      this.getFormSubmissions();
    },
    async removeFilter(selectedField) {
      if (selectedField === "site") {
        const siteIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === selectedField
        );
        const mapServiceIdIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === "mapServiceId"
        );
        const objectIdsIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === "objectIds"
        );
        this.filterChoices.splice(siteIndex, 1);
        this.filterChoices.splice(mapServiceIdIndex, 1);
        this.filterChoices.splice(objectIdsIndex, 1);
      } else if (selectedField === "formTitle") {
        const index = this.filterChoices?.findIndex(
          (f) => f.selectedField === "formTitle"
        );
        this.filterChoices.splice(index, 1);
        this.formFieldFilterChoices = [];
      } else {
        const index = this.filterChoices.findIndex(
          (f) => f.selectedField === selectedField
        );
        this.filterChoices.splice(index, 1);
      }
      await this.$nextTick();
      this.page = 1;
      this.getFormSubmissions();
    },
    getSiteName(formResult) {
      const site = this.siteInfos.find((s) => {
        return (
          s?.featureId === formResult?.featureId &&
          s?.mapServiceId === formResult?.mapServiceId
        );
      });
      return site?.siteName;
    },
    async getMoreFormSubmissions() {
      this.page++;
      await this.getFormSubmissions(this.page);
    },
    async getFormSubmissions(page = 1) {
      if (!navigator.onLine) {
        return;
      }
      const { filterChoices, formFieldFilterChoices } = this;
      const newFilterChoices = [...filterChoices];
      this.loading = true;

      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/form_results/report/utilibot_summary/all`,
        {
          skip: (page - 1) * ITEMS_PER_PAGE,
          take: ITEMS_PER_PAGE,
          filters: newFilterChoices,
          formFieldFilterChoices,
          showNonLocateRequestsOnly: true,
        }
      );
      if (page === 1) {
        this.formSubmissions = results;
      } else {
        this.formSubmissions = [...this.formSubmissions, ...results];
      }
      this.loading = false;
      this.allLoaded = (results?.length ?? 0) < ITEMS_PER_PAGE;
    },
    async viewForm(formResult) {
      const { formResultId } = formResult;
      const { data: selectedFormResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      this.existingFormResultIdMap = {
        formResultId,
      };
      const { map_service_id: selectedMapServiceId } = selectedFormResult ?? {};
      this.selectedFormResult = selectedFormResult;
      this.selectedMapServiceId = selectedMapServiceId;
      const selectedLayer = this.mapServices.find(
        (m) => m.map_service_id === formResult
      );
      if (selectedLayer?.service_type === "U") {
        const [point] = await db.gisDataPoints
          .filter((p) => {
            return (
              p.map_service_id === selectedMapServiceId &&
              p.object_id === formResult?.featureId
            );
          })
          .toArray();
        this.selectedFeatureGlobalId = point?.gis_data_point_id;
      } else if (selectedLayer?.service_type === "F") {
        let queryResult = {};
        const [results] = await db.mapServices
          .filter((m) => m.map_service_id === formResult?.mapServiceId)
          .toArray();
        const mapServiceUrl = results?.service_url;
        const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
          params: {
            objectids: formResult?.featureId,
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          },
        });
        queryResult = data;
        const [feature] = queryResult?.features;
        const { globalIdFieldName } = queryResult;
        this.selectedFeatureGlobalId = feature?.attributes?.[globalIdFieldName];
      }
      this.showEditFormDialog = true;
    },
    async downloadPdf(formResult) {
      const {
        data: { pdf_file_url: pdfFileUrl },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResult.formResultId}`
      );
      if (!pdfFileUrl) {
        return;
      }
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = pdfFileUrl;
      link.click();
      link.remove();
    },
    viewUtiliBots(formResult) {
      this.selectedFormResult = formResult;
      this.showUtilibotDialog = true;
    },
    async getUsers() {
      this.users = await db.users.toCollection().toArray();
    },
    async getFormDefinitions() {
      this.formDefinitions = await db.formDefinitions.toCollection().toArray();
    },
    async getGisInfo() {
      if (!navigator.onLine) {
        return;
      }
      const mapServiceIds = [
        ...new Set(this.formSubmissions.map((r) => r.map_service_id)),
      ];
      const queryPromises = mapServiceIds.map(async (mapServiceId) => {
        const resultsWithMapServiceId = this.formSubmissions.filter(
          (r) => r.map_service_id === mapServiceId
        );
        const objectIds = resultsWithMapServiceId.map((r) => r.feature_id);
        const [mapService] = await db.mapServices
          .filter((m) => m.map_service_id === mapServiceId)
          .toArray();
        const mapServiceUrl = mapService?.service_url;
        if (!mapServiceUrl) {
          return;
        }
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 2000);
          const { data: queryResult } = await axiosWithNoAuth.get(
            `${mapServiceUrl}/query`,
            {
              cancelToken: source.token,
              params: {
                objectids: [...new Set(objectIds)].join(","),
                outFields: "*",
                f: "json",
                token: localStorage.getItem("esri_token"),
              },
            }
          );
          clearTimeout(timeout);
          const { objectIdFieldName, features, error, fields } = queryResult;
          if (error) {
            return [];
          }
          return features.map((feature) => {
            const refFieldName = fields?.find(
              (f) =>
                f.name === mapService?.ref_field ||
                f.alias === mapService?.ref_field
            )?.name;
            return {
              ...feature,
              siteName: feature?.attributes?.[refFieldName],
              featureId: feature?.attributes?.[objectIdFieldName],
              mapServiceId,
            };
          });
        } catch (error) {
          return [];
        }
      });
      const siteInfos = await Promise.all(queryPromises);
      const featureLayerSiteNames = siteInfos.flat();

      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const mapServices = await db.mapServices
        .filter((m) => m.service_type === "U")
        .toArray();
      const utiliSyncLayerSiteNamePromises = mapServices.map(async (m) => {
        const { map_service_id: mapServiceId } = m;
        const gisDataPoints = await db.gisDataPoints
          .filter((p) => p.map_service_id === mapServiceId)
          .toArray();
        const promises = gisDataPoints.map(async (p) => {
          const siteName = await getUtiliSyncLayerFeatureSiteName({
            gisDataFields,
            gisDataValues,
            mapServiceId,
            objectId: p.object_id,
          });
          return {
            featureId: p.object_id,
            mapServiceId: mapServiceId,
            siteName,
          };
        });
        return Promise.all(promises);
      });
      const utiliSyncLayerSiteNames = (
        await Promise.all(utiliSyncLayerSiteNamePromises)
      ).flat();
      this.siteInfos = [...featureLayerSiteNames, ...utiliSyncLayerSiteNames];
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    onRowClick(formSubmission) {
      this.viewForm(formSubmission);
    },
    async getData() {
      try {
        if (localStorage.getItem("bulk-download-complete") !== "true") {
          if (navigator.onLine) {
            await this.bulkDownloadData();
          }
        }
        this.getFormSubmissions();
        this.getUsers();
        this.getGisInfo();
        this.getFormDefinitions();
        this.getMapServices();
        localStorage.setItem("bulk-download-complete", true);
        const lastFullDownloadCompleted = localStorage.getItem(
          "last-full-download-completed"
        );
        const fullDownload =
          !lastFullDownloadCompleted ||
          (lastFullDownloadCompleted &&
            moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
        await this.downloadData(!fullDownload);
      } catch (error) {
        console.log(error);
      } finally {
        this.getUsers();
        this.getGisInfo();
        this.getFormDefinitions();
        this.getMapServices();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.downloadTimer);
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  async mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
  },
  async beforeMount() {
    this.getData();
  },
};
</script>
