<template>
  <v-dialog
    :value="showFormSubmissionsFilterDialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Filter</div>
        <v-spacer />
        <v-btn icon @click="$emit('form-submission-filter-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <div>Filter</div>
        <v-card class="mb-5 px-0" elevation="0">
          <v-card-text class="px-0 mx-0">
            <div class="d-flex flex-wrap gap">
              <v-chip
                v-for="f of displayFilterChoices"
                :key="f.selectedField"
                close
                @click:close="removeFilter(f.selectedField)"
              >
                <div class="chip overflow-hidden text-truncate my-0 py-0">
                  {{ f.selectedField | formSubmissionFields }}
                  <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
                  {{
                    getDisplayMatchValue(f.selectedField, f.selectedMatchValue)
                  }}
                </div>
              </v-chip>

              <v-chip
                v-if="refFieldFilterChoice"
                close
                @click:close="removeFilter('site')"
              >
                <div class="chip overflow-hidden text-truncate my-0 py-0">
                  Reference Field =
                  {{ refFieldFilterChoice.selectedMatchValue }}
                </div>
              </v-chip>

              <v-chip
                v-for="f of formFieldFilterChoices"
                :key="f.selectedFormField"
                close
                @click:close="removeFormFieldFilter()"
              >
                <div class="chip overflow-hidden text-truncate my-0 py-0">
                  {{ getDisplayFormFieldTitle(f.selectedFormField) }}
                  <MatchIcon
                    :value="f.selectedFormFieldMatchChoice"
                    class="mx-2"
                  />
                  {{
                    getDisplayFormFieldMatchValue(
                      f.selectedFormField,
                      f.selectedFormFieldMatchValue
                    )
                  }}
                </div>
              </v-chip>
            </div>
          </v-card-text>
        </v-card>

        <div>Add to Filter</div>
        <v-card>
          <v-card-text>
            <validation-observer
              ref="filterForm"
              v-slot="{ valid: isFormValid }"
            >
              <form
                class="align-center justify-space-between gap"
                :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
              >
                <div
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '400px' : undefined,
                  }"
                >
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Filter"
                    class="field"
                    :style="{
                      width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                    }"
                  >
                    <v-select
                      :items="fieldChoices"
                      v-model="selectedField"
                      item-text="label"
                      item-value="value"
                      label="Field"
                      :error-messages="errors"
                      :success="valid"
                      hide-details
                      class="dropdown"
                    >
                      <template v-slot:item="{ item }">
                        <div class="dropdown-text">{{ item.label }}</div>
                      </template>
                    </v-select>
                  </validation-provider>

                  <validation-provider
                    v-if="selectedField === 'site'"
                    v-slot="{ errors, valid }"
                    name="Layer"
                    class="field"
                    :style="{
                      width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                    }"
                  >
                    <v-select
                      :items="layerChoices"
                      item-text="label"
                      item-value="value"
                      label="Select Layer"
                      :error-messages="errors"
                      :success="valid"
                      hide-details
                      class="dropdown"
                      v-model="selectedLayer"
                    >
                      <template v-slot:item="{ item }">
                        <div class="dropdown-text">{{ item.label }}</div>
                      </template>
                    </v-select>
                  </validation-provider>
                </div>

                <div v-if="filteredMatchChoices.length === 1">
                  <MatchIcon :value="selectedMatchChoice" class="py-3" />
                </div>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Operator"
                  :rules="{ required: true }"
                  v-else
                >
                  <v-select
                    :items="filteredMatchChoices"
                    v-model="selectedMatchChoice"
                    item-text="label"
                    item-value="value"
                    flat
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    class="operator-dropdown"
                    :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
                  >
                    <template #item="{ item: { label, value } }">
                      <div class="py-0 my-0">
                        <MatchIcon :value="value" />
                        {{ label }}
                      </div>
                    </template>

                    <template #selection="{ item: { label, value } }">
                      <div class="py-0 my-0">
                        <MatchIcon :value="value" />
                        {{ $vuetify.breakpoint.smAndUp ? undefined : label }}
                      </div>
                    </template>
                  </v-select>
                </validation-provider>

                <div
                  class="field"
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '400px' : undefined,
                  }"
                >
                  <v-menu
                    v-if="fieldType === FIELD_TYPES.DATE"
                    v-model="openDatePicker"
                    :close-on-click="false"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Value"
                        :rules="{ required: true }"
                      >
                        <v-text-field
                          v-on="on"
                          v-model="selectedMatchValue"
                          clearable
                          :error-messages="errors"
                          :success="valid"
                          label="Value"
                          hide-details
                        >
                          <template #append>
                            <v-btn
                              small
                              icon
                              @click="
                                on.click($event);
                                openDatePicker = true;
                              "
                            >
                              <v-icon color="#3F51B5">
                                {{ mdiCalendar }}
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-if="openDatePicker"
                      v-model="selectedMatchValue"
                      :range="selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE"
                      @click:date="onDateClick()"
                      v-click-outside="onDatePickerClickOutside"
                    >
                    </v-date-picker>
                  </v-menu>
                  <template v-else>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Value"
                      :rules="{ required: true }"
                    >
                      <v-autocomplete
                        v-model="selectedMatchValue"
                        :items="valueChoices"
                        item-text="label"
                        item-value="value"
                        :error-messages="errors"
                        :success="valid"
                        label="Value"
                        hide-details
                        class="dropdown"
                      >
                        <template v-slot:item="{ item }">
                          <section>
                            <div
                              v-if="selectedField === 'formTitle'"
                              class="caption pt-2"
                            >
                              {{ item.layer }}
                            </div>
                            <div
                              :class="
                                selectedField === 'formTitle' ? 'pb-2' : ''
                              "
                            >
                              {{ item.label }}
                            </div>
                          </section>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </template>
                </div>

                <v-btn
                  icon
                  color="#3F51B5"
                  @click="addToFilter"
                  :disabled="!isFormValid"
                >
                  <v-icon>
                    {{ mdiFilterPlus }}
                  </v-icon>
                </v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>

        <v-btn
          text
          v-if="formTitleAdded"
          color="primary"
          class="my-3 d-flex align-center"
          @click="showSearchFormFields = !showSearchFormFields"
        >
          <div>Search Form Fields</div>
          <v-icon>
            {{ showSearchFormFields ? mdiChevronUp : mdiChevronDown }}
          </v-icon>
        </v-btn>

        <v-card v-if="showSearchFormFields">
          <v-card-text>
            <validation-observer
              ref="formFieldFilterForm"
              v-slot="{ valid: isFormFieldFilterFormValid }"
            >
              <form
                class="align-center justify-space-between gap"
                :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Question"
                  rules="required"
                  class="field"
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                  }"
                >
                  <v-select
                    :items="formFieldChoices"
                    v-model="selectedFormField"
                    item-text="label"
                    item-value="value"
                    label="Question"
                    :error-messages="errors"
                    :success="valid"
                    hide-details
                    class="dropdown"
                  >
                    <template v-slot:item="{ item }">
                      <div class="dropdown-text">{{ item.label }}</div>
                    </template>
                  </v-select>
                </validation-provider>

                <div v-if="formFieldFilteredMatchChoices.length === 1">
                  <MatchIcon :value="selectedMatchChoice" />
                </div>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Operator"
                  rules="required"
                  v-else
                >
                  <v-select
                    :items="formFieldFilteredMatchChoices"
                    v-model="selectedFormFieldMatchChoice"
                    item-text="label"
                    item-value="value"
                    flat
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    class="operator-dropdown"
                    :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
                  >
                    <template #item="{ item: { label, value } }">
                      <div class="py-0 my-0">
                        <MatchIcon :value="value" />
                        {{ label }}
                      </div>
                    </template>

                    <template #selection="{ item: { value } }">
                      <div class="py-0 my-0">
                        <MatchIcon :value="value" />
                      </div>
                    </template>
                  </v-select>
                </validation-provider>

                <div
                  class="field"
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                  }"
                >
                  <template
                    v-if="
                      ['TEXT', 'EMAIL', 'NUMBER'].includes(selectedFormItemType)
                    "
                  >
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Value"
                      :rules="{
                        email: selectedFormItemType === 'EMAIL',
                        number: selectedFormItemType === 'NUMBER',
                      }"
                      class="field"
                    >
                      <v-text-field
                        v-model="selectedFormFieldMatchValue"
                        :error-messages="errors"
                        :success="valid"
                        label="Value"
                        :type="
                          selectedFormItemType === 'NUMBER' ? 'number' : 'text'
                        "
                        hide-details
                      >
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <template v-else-if="['DATE'].includes(selectedFormItemType)">
                    <v-menu
                      :close-on-click="false"
                      :close-on-content-click="false"
                      v-model="openFormFieldDatePicker"
                      class="field"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Value"
                          rules="required"
                        >
                          <v-text-field
                            v-on="on"
                            v-model="selectedFormFieldMatchValue"
                            clearable
                            :error-messages="errors"
                            :success="valid"
                            label="Value"
                            hide-details
                          >
                            <template #append>
                              <v-btn
                                small
                                icon
                                @click="
                                  on.click($event);
                                  openFormFieldDatePicker = true;
                                "
                              >
                                <v-icon color="primary">
                                  {{ mdiCalendar }}
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-if="openFormFieldDatePicker"
                        v-model="selectedFormFieldMatchValue"
                        :range="
                          selectedFormFieldMatchChoice ===
                          MATCH_CHOICE_VALUES.RANGE
                        "
                        @click:date="onFormFieldDateClick()"
                        v-click-outside="onFormFieldDatePickerClickOutside"
                      >
                      </v-date-picker>
                    </v-menu>
                  </template>
                  <template
                    v-else-if="selectedFormItemType === 'SINGLE_SELECT'"
                  >
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Value"
                      rules="required"
                      class="field"
                    >
                      <v-select
                        :items="selectedFormItemChoices"
                        v-model="selectedFormFieldMatchValue"
                        item-text="label"
                        item-value="value"
                        label="Value"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        class="dropdown"
                      >
                        <template v-slot:item="{ item }">
                          <div class="dropdown-text">{{ item.label }}</div>
                        </template>
                      </v-select>
                    </validation-provider>
                  </template>
                  <template v-else>
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Value"
                      rules="required"
                      class="field"
                    >
                      <v-text-field
                        label="Value"
                        v-model="selectedFormFieldMatchValue"
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                      >
                      </v-text-field>
                    </validation-provider>
                  </template>
                </div>

                <v-btn
                  icon
                  color="primary"
                  @click="addToFormFieldFilter"
                  :disabled="!isFormFieldFilterFormValid"
                >
                  <v-icon>
                    {{ mdiFilterPlus }}
                  </v-icon>
                </v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn
          elevation="2"
          color="#3F51B5"
          @click="updateFilter"
          :disabled="!filterChanged"
          :dark="filterChanged"
        >
          Update Filter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiFilterPlus,
  mdiCalendar,
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiCalendarRange,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import FORM_RESULT_STATUS from "@/constants/formResultStatus";
import FIELD_TYPES from "@/constants/fieldTypes";
import { cloneDeep } from "lodash";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";

const fieldChoices = [
  { label: "Reference Field", value: "site", fieldType: FIELD_TYPES.STRING },
  { label: "Form", value: "formTitle", fieldType: FIELD_TYPES.STRING },
  {
    label: "Submitted By",
    value: "submittedBy",
    fieldType: FIELD_TYPES.STRING,
  },
  {
    label: "Last Updated",
    value: "updatedOn",
    fieldType: FIELD_TYPES.DATE,
  },
  {
    label: "Utilibot Status",
    value: "overallTaskStatus",
    fieldType: FIELD_TYPES.STRING,
  },
];

const statusChoices = [
  { label: "Complete Error", value: FORM_RESULT_STATUS.COMPLETED_ERROR },
  { label: "Processing", value: FORM_RESULT_STATUS.PROCESSING },
  { label: "Completed Success", value: FORM_RESULT_STATUS.COMPLETED_SUCCESS },
  { label: "Dead Letter Queue", value: FORM_RESULT_STATUS.DEAD_LETTER_QUEUE },
];

export default {
  name: "FormSubmissionsFilterDialog",
  data() {
    return {
      mdiClose,
      mdiFilterPlus,
      mdiCalendar,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      mdiCalendarRange,
      mdiChevronUp,
      mdiChevronDown,
      FIELD_TYPES,
      MATCH_CHOICE_VALUES,
      fieldChoices,
      statusChoices,
      filterChoices: [],
      selectedMatchValue: undefined,
      selectedMatchChoice: undefined,
      selectedField: undefined,
      filterChanged: false,
      openDatePicker: false,
      selectedLayer: undefined,
      showSearchFormFields: false,
      selectedFormField: undefined,
      selectedFormFieldMatchChoice: undefined,
      selectedFormFieldMatchValue: undefined,
      formFieldFilterChoices: [],
      openFormFieldDatePicker: false,
    };
  },
  props: {
    savedFilterChoices: Array,
    savedFormFieldFilterChoices: Array,
    showFormSubmissionsFilterDialog: Boolean,
    users: Array,
    mapServices: Array,
    formDefinitions: Array,
    siteInfos: Array,
  },
  components: {
    MatchIcon,
  },
  computed: {
    refFieldFilterChoice() {
      return this.filterChoices.find((f) => f.selectedField === "site");
    },
    displayFilterChoices() {
      return this.filterChoices.filter((f) => {
        return !["mapServiceId", "objectIds", "site"].includes(f.selectedField);
      });
    },
    layerChoices() {
      return this.mapServices.map((m) => {
        const { map_service_id: value, service_name: label } = m;
        return {
          label,
          value,
        };
      });
    },
    formTitleAdded() {
      return Boolean(
        this.filterChoices.find((f) => f.selectedField === "formTitle")
      );
    },
    selectedFormItemChoices() {
      return this.selectedFormItem?.question?.options ?? [];
    },
    selectedFormItemType() {
      return this.selectedFormItem?.question?.type;
    },
    selectedFormItem() {
      const { selectedFormField, selectedFormDefinition } = this;
      const selectedItem = selectedFormDefinition?.form?.sections
        ?.map((s) => s.items)
        ?.flat()
        ?.find((it) => it.id === selectedFormField);
      return selectedItem;
    },
    formFieldFilteredMatchChoices() {
      const { selectedFormItemType: type } = this;
      if (["TEXT", "EMAIL"].includes(type)) {
        return [
          { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
          {
            label: "Does Not Contain",
            value: MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN,
          },
        ];
      } else if (["DATE", "TIME"].includes(type)) {
        return [
          {
            label: "On or After",
            value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
          },
          {
            label: "On or Before",
            value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
          },
          {
            label: "Range",
            value: MATCH_CHOICE_VALUES.RANGE,
          },
        ];
      } else if (type === "NUMBER") {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Greater Than", value: MATCH_CHOICE_VALUES.GREATER_THAN },
          {
            label: "Greater Than or Equal",
            value: MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL,
          },
          { label: "Less Than", value: MATCH_CHOICE_VALUES.LESS_THAN },
          {
            label: "Less Than or Equal",
            value: MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL,
          },
          {
            label: "Does not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      }
      return [
        { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
        {
          label: "Does Not Equal",
          value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
        },
      ];
    },
    formFieldChoices() {
      const { selectedFormDefinition } = this;
      return (
        selectedFormDefinition?.form?.sections
          ?.map((s) => s.items)
          ?.flat()
          ?.filter((it) => {
            return (
              it?.type === "QUESTION" &&
              ["TEXT", "EMAIL", "DATE", "NUMBER", "SINGLE_SELECT"].includes(
                it?.question?.type
              )
            );
          })
          ?.map((it) => {
            return { label: it?.question?.label, value: it?.id };
          }) ?? []
      );
    },
    selectedFormDefinition() {
      const { selectedMatchValue } =
        this.filterChoices.find((f) => f.selectedField === "formTitle") ?? {};
      return this.formDefinitions.find(
        (f) => f.form_definition_id === selectedMatchValue
      );
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }

      const activeUsers = this.users
        .filter((u) => u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Active Users",
          };
        });
      const inactiveUsers = this.users
        .filter((u) => !u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Inactive Users",
          };
        });
      return [
        { header: "Active Users" },
        ...activeUsers,
        { header: "Inactive Users" },
        ...inactiveUsers,
      ];
    },
    formDefinitionChoices() {
      return (
        this.formDefinitions
          ?.map((f) => {
            return {
              label: f?.form?.formDescription?.title,
              value: f.form_definition_id,
              layer: this.mapServices.find((s) => {
                return s?.map_service_id === f.map_service_id;
              }).service_name,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    },
    refFieldChoices() {
      return (
        this.siteInfos
          ?.filter((s) => {
            return s?.map_service_id === this.selectedLayer && s?.siteName;
          })
          ?.map((s) => {
            return {
              label: s?.siteName,
              value: s?.siteName,
            };
          }) ?? []
      );
    },
    valueChoices() {
      if (this.selectedField === "submittedBy") {
        return this.userChoices;
      } else if (this.selectedField === "overallTaskStatus") {
        return statusChoices;
      } else if (this.selectedField === "formTitle") {
        return this.formDefinitionChoices;
      } else if (this.selectedField === "site") {
        return this.refFieldChoices;
      }
      return [];
    },
    fieldType() {
      return this.fieldChoices.find((f) => f.value === this.selectedField)
        ?.fieldType;
    },
    filteredMatchChoices() {
      const { fieldType, selectedField } = this;
      if (fieldType === FIELD_TYPES.STRING) {
        if (["site"].includes(selectedField)) {
          return [
            { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
            {
              label: "Does Not Equal",
              value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
            },
            { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
            {
              label: "Does Not Contain",
              value: MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN,
            },
          ];
        } else if (
          ["submittedBy", "overallTaskStatus", "formTitle"].includes(
            selectedField
          )
        ) {
          return [
            { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
            {
              label: "Does Not Equal",
              value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
            },
          ];
        }
      } else if (fieldType === FIELD_TYPES.NUMBER) {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Greater Than", value: MATCH_CHOICE_VALUES.GREATER_THAN },
          {
            label: "Greater Than or Equal",
            value: MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL,
          },
          { label: "Less Than", value: MATCH_CHOICE_VALUES.LESS_THAN },
          {
            label: "Less Than or Equal",
            value: MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL,
          },
          {
            label: "Does not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      } else if (fieldType === FIELD_TYPES.DATE) {
        return [
          {
            label: "On or After",
            value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
          },
          {
            label: "On or Before",
            value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
          },
          {
            label: "Range",
            value: MATCH_CHOICE_VALUES.RANGE,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    async addToFormFieldFilter() {
      const success = await this.$refs.formFieldFilterForm.validate();
      if (!success) {
        return;
      }
      await this.$nextTick();
      const {
        selectedFormField,
        selectedFormFieldMatchChoice,
        selectedFormFieldMatchValue,
      } = this;
      const filter = {
        selectedFormField,
        selectedFormFieldMatchChoice,
        selectedFormFieldMatchValue,
      };
      this.formFieldFilterChoices = [filter];
      this.selectedField = undefined;
      this.selectedMatchChoice = MATCH_CHOICE_VALUES.EQUALS;
      this.selectedMatchValue = undefined;
      this.selectedFormField = undefined;
      this.selectedFormFieldMatchChoice = undefined;
      this.selectedFormFieldMatchValue = undefined;
      this.filterChanged = true;
      this.$refs.formFieldFilterForm?.reset();
    },
    async addToFilter() {
      const success = await this.$refs.filterForm.validate();
      if (!success) {
        return;
      }
      await this.$nextTick();
      const { selectedField, selectedMatchChoice, selectedMatchValue } = this;
      const filter = {
        selectedField,
        selectedMatchChoice,
        selectedMatchValue,
      };

      if (selectedField === "site") {
        const mapServiceIdFilter = {
          selectedField: "mapServiceId",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: this.selectedLayer,
        };

        const objectIds = this.siteInfos
          .filter((s) => {
            return (
              s?.map_service_id === this.selectedLayer &&
              s?.siteName === selectedMatchValue
            );
          })
          .map((s) => {
            return s.feature_id;
          });

        const objectIdFilter = {
          selectedField: "objectIds",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: objectIds,
        };

        const siteNameFilter = {
          selectedField: "site",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue,
        };

        this.filterChoices.push(
          mapServiceIdFilter,
          objectIdFilter,
          siteNameFilter
        );
      } else if (selectedField === "formTitle") {
        const filter = {
          selectedField,
          selectedMatchChoice,
          selectedMatchValue,
        };
        const index = this.filterChoices.findIndex((fc) => {
          return fc.selectedField === selectedField;
        });
        if (index >= 0) {
          this.filterChoices.splice(index, 1);
        }
        this.filterChoices.push(filter);
        this.showSearchFormFields = false;
      } else {
        const index = this.filterChoices.findIndex((fc) => {
          return fc.selectedField === selectedField;
        });
        if (index >= 0) {
          this.filterChoices.splice(index, 1);
        }
        this.filterChoices.push(filter);
      }

      this.selectedField = undefined;
      this.selectedMatchChoice = MATCH_CHOICE_VALUES.EQUALS;
      this.selectedMatchValue = undefined;
      this.filterChanged = true;
      this.$refs.filterForm?.reset();
    },
    removeFilter(selectedField) {
      if (selectedField === "site") {
        const siteIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === selectedField
        );
        const mapServiceIdIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === "mapServiceId"
        );
        const objectIdsIndex = this.filterChoices.findIndex(
          (f) => f.selectedField === "objectIds"
        );
        this.filterChoices.splice(siteIndex, 1);
        this.filterChoices.splice(mapServiceIdIndex, 1);
        this.filterChoices.splice(objectIdsIndex, 1);
      } else if (selectedField === "formTitle") {
        const index = this.filterChoices?.findIndex(
          (f) => f.selectedField === "formTitle"
        );
        this.filterChoices.splice(index, 1);
        this.formFieldFilterChoices = [];
        this.showSearchFormFields = false;
      } else {
        const index = this.filterChoices.findIndex(
          (f) => f.selectedField === selectedField
        );
        this.filterChoices.splice(index, 1);
      }
      this.filterChanged = true;
    },
    removeFormFieldFilter() {
      this.formFieldFilterChoices = [];
      this.filterChanged = true;
      this.showSearchFormFields = false;
    },
    getDisplayFormFieldTitle(selectedFormFieldId) {
      const formDefId = this.filterChoices?.find(
        (f) => f.selectedField === "formTitle"
      )?.selectedMatchValue;
      const selectedFormDefinition = this.formDefinitions.find(
        (f) => f.form_definition_id === formDefId
      );

      return selectedFormDefinition?.form?.sections
        ?.map((s) => s.items)
        ?.flat()
        ?.find((f) => +f.id === +selectedFormFieldId)?.question?.label;
    },
    getDisplayFormFieldMatchValue(
      selectedFormFieldId,
      selectedFormFieldMatchValue
    ) {
      const formDefId = this.filterChoices?.find(
        (f) => f.selectedField === "formTitle"
      )?.selectedMatchValue;
      const selectedFormDefinition = this.formDefinitions.find(
        (f) => f.form_definition_id === formDefId
      );
      const item = selectedFormDefinition?.form?.sections
        ?.map((s) => s.items)
        ?.flat()
        ?.find((f) => +f.id === +selectedFormFieldId);

      if (item?.question?.type === "SINGLE_SELECT") {
        return item?.question?.options?.find(
          (it) => it.value === selectedFormFieldMatchValue
        )?.label;
      } else if (item?.question?.type === "DATE") {
        if (Array.isArray(selectedFormFieldMatchValue)) {
          return selectedFormFieldMatchValue.join(" - ");
        }
        return selectedFormFieldMatchValue;
      }
      return item?.value ?? selectedFormFieldMatchValue;
    },
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "submittedBy") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        if (user) {
          const { f_name: fName, l_name: lName } = user;
          return `${fName} ${lName}`;
        } else {
          return selectedMatchValue;
        }
      } else if (selectedField === "updatedOn") {
        if (Array.isArray(selectedMatchValue)) {
          return selectedMatchValue.join(" - ");
        }
        return selectedMatchValue;
      } else if (selectedField === "formTitle") {
        const selectedFormDefinition = this.formDefinitions.find(
          (f) => f.form_definition_id === selectedMatchValue
        );
        return selectedFormDefinition?.form?.formDescription?.title;
      }
      return selectedMatchValue;
    },
    updateFilter() {
      const { filterChoices, formFieldFilterChoices } = this;
      const filter = {
        filterChoices,
        formFieldFilterChoices,
      };
      this.$emit("update-filter", filter);
    },
    onDateClick() {
      if (this.selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
        this.openDatePicker = (this.selectedMatchValue?.length ?? 0) < 2;
      } else {
        this.openDatePicker = false;
      }
    },
    onFormFieldDateClick() {
      if (this.selectedFormFieldMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
        this.openFormFieldDatePicker =
          (this.selectedFormFieldMatchValue?.length ?? 0) < 2;
      } else {
        this.openFormFieldDatePicker = false;
      }
    },
    onDatePickerClickOutside() {
      this.openDatePicker = false;
    },
    onFormFieldDatePickerClickOutside() {
      this.openFormFieldDatePicker = false;
    },
  },
  watch: {
    selectedField: {
      immediate: true,
      async handler() {
        this.selectedMatchChoice = undefined;
      },
    },
    filteredMatchChoices: {
      deep: true,
      handler(val) {
        const [firstChoice] = val;
        this.selectedMatchChoice = firstChoice?.value;
      },
    },
    async selectedMatchChoice() {
      this.selectedMatchValue = undefined;
    },
  },
  beforeMount() {
    this.filterChoices = cloneDeep(this.savedFilterChoices);
    this.formFieldFilterChoices = cloneDeep(this.savedFormFieldFilterChoices);
  },
};
</script>

<style scoped>
.field {
  width: 100%;
}

.field-select >>> .v-input__slot::before {
  border-style: none;
}

.gap {
  gap: 15px;
}

.dropdown-text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  max-width: 250px;
}

.operator-dropdown {
  max-width: 70px;
}
</style>
