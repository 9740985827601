var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showFormSubmissionsFilterDialog,
        "max-width": "600px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Filter")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("form-submission-filter-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c("div", [_vm._v("Filter")]),
              _c(
                "v-card",
                { staticClass: "mb-5 px-0", attrs: { elevation: "0" } },
                [
                  _c("v-card-text", { staticClass: "px-0 mx-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap gap" },
                      [
                        _vm._l(_vm.displayFilterChoices, function(f) {
                          return _c(
                            "v-chip",
                            {
                              key: f.selectedField,
                              attrs: { close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFilter(f.selectedField)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chip overflow-hidden text-truncate my-0 py-0"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formSubmissionFields")(
                                          f.selectedField
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("MatchIcon", {
                                    staticClass: "mx-2",
                                    attrs: { value: f.selectedMatchChoice }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDisplayMatchValue(
                                          f.selectedField,
                                          f.selectedMatchValue
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _vm.refFieldFilterChoice
                          ? _c(
                              "v-chip",
                              {
                                attrs: { close: "" },
                                on: {
                                  "click:close": function($event) {
                                    return _vm.removeFilter("site")
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "chip overflow-hidden text-truncate my-0 py-0"
                                  },
                                  [
                                    _vm._v(
                                      " Reference Field = " +
                                        _vm._s(
                                          _vm.refFieldFilterChoice
                                            .selectedMatchValue
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.formFieldFilterChoices, function(f) {
                          return _c(
                            "v-chip",
                            {
                              key: f.selectedFormField,
                              attrs: { close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFormFieldFilter()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chip overflow-hidden text-truncate my-0 py-0"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDisplayFormFieldTitle(
                                          f.selectedFormField
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("MatchIcon", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      value: f.selectedFormFieldMatchChoice
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDisplayFormFieldMatchValue(
                                          f.selectedFormField,
                                          f.selectedFormFieldMatchValue
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("Add to Filter")]),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("validation-observer", {
                        ref: "filterForm",
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var isFormValid = ref.valid
                              return [
                                _c(
                                  "form",
                                  {
                                    staticClass:
                                      "align-center justify-space-between gap",
                                    class: {
                                      "d-flex": _vm.$vuetify.breakpoint.smAndUp
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          width: _vm.$vuetify.breakpoint.smAndUp
                                            ? "400px"
                                            : undefined
                                        }
                                      },
                                      [
                                        _c("validation-provider", {
                                          staticClass: "field",
                                          style: {
                                            width: _vm.$vuetify.breakpoint
                                              .smAndUp
                                              ? "100%"
                                              : undefined
                                          },
                                          attrs: { name: "Filter" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-select", {
                                                      staticClass: "dropdown",
                                                      attrs: {
                                                        items: _vm.fieldChoices,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        label: "Field",
                                                        "error-messages": errors,
                                                        success: valid,
                                                        "hide-details": ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.label
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedField,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedField = $$v
                                                        },
                                                        expression:
                                                          "selectedField"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                        _vm.selectedField === "site"
                                          ? _c("validation-provider", {
                                              staticClass: "field",
                                              style: {
                                                width: _vm.$vuetify.breakpoint
                                                  .smAndUp
                                                  ? "100%"
                                                  : undefined
                                              },
                                              attrs: { name: "Layer" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      var valid = ref.valid
                                                      return [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "dropdown",
                                                          attrs: {
                                                            items:
                                                              _vm.layerChoices,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                            label:
                                                              "Select Layer",
                                                            "error-messages": errors,
                                                            success: valid,
                                                            "hide-details": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "item",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var item =
                                                                    ref.item
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "dropdown-text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.selectedLayer,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selectedLayer = $$v
                                                            },
                                                            expression:
                                                              "selectedLayer"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm.filteredMatchChoices.length === 1
                                      ? _c(
                                          "div",
                                          [
                                            _c("MatchIcon", {
                                              staticClass: "py-3",
                                              attrs: {
                                                value: _vm.selectedMatchChoice
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("validation-provider", {
                                          attrs: {
                                            name: "Operator",
                                            rules: { required: true }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "operator-dropdown",
                                                      class: {
                                                        "field-select":
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp
                                                      },
                                                      attrs: {
                                                        items:
                                                          _vm.filteredMatchChoices,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        flat: "",
                                                        "hide-details": "",
                                                        "error-messages": errors,
                                                        success: valid
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function(ref) {
                                                              var ref_item =
                                                                ref.item
                                                              var label =
                                                                ref_item.label
                                                              var value =
                                                                ref_item.value
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 my-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MatchIcon",
                                                                      {
                                                                        attrs: {
                                                                          value: value
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          label
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var ref_item =
                                                                ref.item
                                                              var label =
                                                                ref_item.label
                                                              var value =
                                                                ref_item.value
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 my-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MatchIcon",
                                                                      {
                                                                        attrs: {
                                                                          value: value
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .smAndUp
                                                                            ? undefined
                                                                            : label
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedMatchChoice,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedMatchChoice = $$v
                                                        },
                                                        expression:
                                                          "selectedMatchChoice"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field",
                                        style: {
                                          width: _vm.$vuetify.breakpoint.smAndUp
                                            ? "400px"
                                            : undefined
                                        }
                                      },
                                      [
                                        _vm.fieldType === _vm.FIELD_TYPES.DATE
                                          ? _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "close-on-click": false,
                                                  "close-on-content-click": false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name: "Value",
                                                                rules: {
                                                                  required: true
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      var valid =
                                                                        ref.valid
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                clearable:
                                                                                  "",
                                                                                "error-messages": errors,
                                                                                success: valid,
                                                                                label:
                                                                                  "Value",
                                                                                "hide-details":
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "append",
                                                                                    fn: function() {
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                "",
                                                                                              icon:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                on.click(
                                                                                                  $event
                                                                                                )
                                                                                                _vm.openDatePicker = true
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "#3F51B5"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.mdiCalendar
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    },
                                                                                    proxy: true
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  _vm.selectedMatchValue,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.selectedMatchValue = $$v
                                                                                },
                                                                                expression:
                                                                                  "selectedMatchValue"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.openDatePicker,
                                                  callback: function($$v) {
                                                    _vm.openDatePicker = $$v
                                                  },
                                                  expression: "openDatePicker"
                                                }
                                              },
                                              [
                                                _vm.openDatePicker
                                                  ? _c("v-date-picker", {
                                                      directives: [
                                                        {
                                                          name: "click-outside",
                                                          rawName:
                                                            "v-click-outside",
                                                          value:
                                                            _vm.onDatePickerClickOutside,
                                                          expression:
                                                            "onDatePickerClickOutside"
                                                        }
                                                      ],
                                                      attrs: {
                                                        range:
                                                          _vm.selectedMatchChoice ===
                                                          _vm
                                                            .MATCH_CHOICE_VALUES
                                                            .RANGE
                                                      },
                                                      on: {
                                                        "click:date": function(
                                                          $event
                                                        ) {
                                                          return _vm.onDateClick()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedMatchValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedMatchValue = $$v
                                                        },
                                                        expression:
                                                          "selectedMatchValue"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Value",
                                                  rules: { required: true }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-autocomplete", {
                                                            staticClass:
                                                              "dropdown",
                                                            attrs: {
                                                              items:
                                                                _vm.valueChoices,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              "error-messages": errors,
                                                              success: valid,
                                                              label: "Value",
                                                              "hide-details": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "section",
                                                                        [
                                                                          _vm.selectedField ===
                                                                          "formTitle"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "caption pt-2"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.layer
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              class:
                                                                                _vm.selectedField ===
                                                                                "formTitle"
                                                                                  ? "pb-2"
                                                                                  : ""
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.selectedMatchValue,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedMatchValue = $$v
                                                              },
                                                              expression:
                                                                "selectedMatchValue"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          color: "#3F51B5",
                                          disabled: !isFormValid
                                        },
                                        on: { click: _vm.addToFilter }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiFilterPlus) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.formTitleAdded
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-3 d-flex align-center",
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showSearchFormFields = !_vm.showSearchFormFields
                        }
                      }
                    },
                    [
                      _c("div", [_vm._v("Search Form Fields")]),
                      _c("v-icon", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showSearchFormFields
                                ? _vm.mdiChevronUp
                                : _vm.mdiChevronDown
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSearchFormFields
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("validation-observer", {
                            ref: "formFieldFilterForm",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var isFormFieldFilterFormValid = ref.valid
                                    return [
                                      _c(
                                        "form",
                                        {
                                          staticClass:
                                            "align-center justify-space-between gap",
                                          class: {
                                            "d-flex":
                                              _vm.$vuetify.breakpoint.smAndUp
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            staticClass: "field",
                                            style: {
                                              width: _vm.$vuetify.breakpoint
                                                .smAndUp
                                                ? "100%"
                                                : undefined
                                            },
                                            attrs: {
                                              name: "Question",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    var valid = ref.valid
                                                    return [
                                                      _c("v-select", {
                                                        staticClass: "dropdown",
                                                        attrs: {
                                                          items:
                                                            _vm.formFieldChoices,
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          label: "Question",
                                                          "error-messages": errors,
                                                          success: valid,
                                                          "hide-details": ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "item",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dropdown-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.label
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.selectedFormField,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedFormField = $$v
                                                          },
                                                          expression:
                                                            "selectedFormField"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm.formFieldFilteredMatchChoices
                                            .length === 1
                                            ? _c(
                                                "div",
                                                [
                                                  _c("MatchIcon", {
                                                    attrs: {
                                                      value:
                                                        _vm.selectedMatchChoice
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c("validation-provider", {
                                                attrs: {
                                                  name: "Operator",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-select", {
                                                            staticClass:
                                                              "operator-dropdown",
                                                            class: {
                                                              "field-select":
                                                                _vm.$vuetify
                                                                  .breakpoint
                                                                  .smAndUp
                                                            },
                                                            attrs: {
                                                              items:
                                                                _vm.formFieldFilteredMatchChoices,
                                                              "item-text":
                                                                "label",
                                                              "item-value":
                                                                "value",
                                                              flat: "",
                                                              "hide-details":
                                                                "",
                                                              "error-messages": errors,
                                                              success: valid
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var ref_item =
                                                                      ref.item
                                                                    var label =
                                                                      ref_item.label
                                                                    var value =
                                                                      ref_item.value
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "py-0 my-0"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "MatchIcon",
                                                                            {
                                                                              attrs: {
                                                                                value: value
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                label
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key:
                                                                    "selection",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var value =
                                                                      ref.item
                                                                        .value
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "py-0 my-0"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "MatchIcon",
                                                                            {
                                                                              attrs: {
                                                                                value: value
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.selectedFormFieldMatchChoice,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedFormFieldMatchChoice = $$v
                                                              },
                                                              expression:
                                                                "selectedFormFieldMatchChoice"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              style: {
                                                width: _vm.$vuetify.breakpoint
                                                  .smAndUp
                                                  ? "100%"
                                                  : undefined
                                              }
                                            },
                                            [
                                              [
                                                "TEXT",
                                                "EMAIL",
                                                "NUMBER"
                                              ].includes(
                                                _vm.selectedFormItemType
                                              )
                                                ? [
                                                    _c("validation-provider", {
                                                      staticClass: "field",
                                                      attrs: {
                                                        name: "Value",
                                                        rules: {
                                                          email:
                                                            _vm.selectedFormItemType ===
                                                            "EMAIL",
                                                          number:
                                                            _vm.selectedFormItemType ===
                                                            "NUMBER"
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              var valid =
                                                                ref.valid
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      "error-messages": errors,
                                                                      success: valid,
                                                                      label:
                                                                        "Value",
                                                                      type:
                                                                        _vm.selectedFormItemType ===
                                                                        "NUMBER"
                                                                          ? "number"
                                                                          : "text",
                                                                      "hide-details":
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.selectedFormFieldMatchValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.selectedFormFieldMatchValue = $$v
                                                                      },
                                                                      expression:
                                                                        "selectedFormFieldMatchValue"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ]
                                                : ["DATE"].includes(
                                                    _vm.selectedFormItemType
                                                  )
                                                ? [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        staticClass: "field",
                                                        attrs: {
                                                          "close-on-click": false,
                                                          "close-on-content-click": false
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        name:
                                                                          "Value",
                                                                        rules:
                                                                          "required"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "default",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var errors =
                                                                                ref.errors
                                                                              var valid =
                                                                                ref.valid
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs: {
                                                                                        clearable:
                                                                                          "",
                                                                                        "error-messages": errors,
                                                                                        success: valid,
                                                                                        label:
                                                                                          "Value",
                                                                                        "hide-details":
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "append",
                                                                                            fn: function() {
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        "",
                                                                                                      icon:
                                                                                                        ""
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        on.click(
                                                                                                          $event
                                                                                                        )
                                                                                                        _vm.openFormFieldDatePicker = true
                                                                                                      }
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "primary"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm.mdiCalendar
                                                                                                            ) +
                                                                                                            " "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            },
                                                                                            proxy: true
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                      model: {
                                                                                        value:
                                                                                          _vm.selectedFormFieldMatchValue,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.selectedFormFieldMatchValue = $$v
                                                                                        },
                                                                                        expression:
                                                                                          "selectedFormFieldMatchValue"
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    }
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.openFormFieldDatePicker,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.openFormFieldDatePicker = $$v
                                                          },
                                                          expression:
                                                            "openFormFieldDatePicker"
                                                        }
                                                      },
                                                      [
                                                        _vm.openFormFieldDatePicker
                                                          ? _c(
                                                              "v-date-picker",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "click-outside",
                                                                    rawName:
                                                                      "v-click-outside",
                                                                    value:
                                                                      _vm.onFormFieldDatePickerClickOutside,
                                                                    expression:
                                                                      "onFormFieldDatePickerClickOutside"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  range:
                                                                    _vm.selectedFormFieldMatchChoice ===
                                                                    _vm
                                                                      .MATCH_CHOICE_VALUES
                                                                      .RANGE
                                                                },
                                                                on: {
                                                                  "click:date": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onFormFieldDateClick()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.selectedFormFieldMatchValue,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedFormFieldMatchValue = $$v
                                                                  },
                                                                  expression:
                                                                    "selectedFormFieldMatchValue"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm.selectedFormItemType ===
                                                  "SINGLE_SELECT"
                                                ? [
                                                    _c("validation-provider", {
                                                      staticClass: "field",
                                                      attrs: {
                                                        name: "Value",
                                                        rules: "required"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              var valid =
                                                                ref.valid
                                                              return [
                                                                _c("v-select", {
                                                                  staticClass:
                                                                    "dropdown",
                                                                  attrs: {
                                                                    items:
                                                                      _vm.selectedFormItemChoices,
                                                                    "item-text":
                                                                      "label",
                                                                    "item-value":
                                                                      "value",
                                                                    label:
                                                                      "Value",
                                                                    "error-messages": errors,
                                                                    success: valid,
                                                                    "hide-details":
                                                                      ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "item",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var item =
                                                                            ref.item
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "dropdown-text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                  model: {
                                                                    value:
                                                                      _vm.selectedFormFieldMatchValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.selectedFormFieldMatchValue = $$v
                                                                    },
                                                                    expression:
                                                                      "selectedFormFieldMatchValue"
                                                                  }
                                                                })
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ]
                                                : [
                                                    _c("validation-provider", {
                                                      staticClass: "field",
                                                      attrs: {
                                                        name: "Value",
                                                        rules: "required"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              var valid =
                                                                ref.valid
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Value",
                                                                      "error-messages": errors,
                                                                      success: valid,
                                                                      "hide-details":
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.selectedFormFieldMatchValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.selectedFormFieldMatchValue = $$v
                                                                      },
                                                                      expression:
                                                                        "selectedFormFieldMatchValue"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ]
                                            ],
                                            2
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                                disabled: !isFormFieldFilterFormValid
                                              },
                                              on: {
                                                click: _vm.addToFormFieldFilter
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiFilterPlus) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1701605268
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "2",
                    color: "#3F51B5",
                    disabled: !_vm.filterChanged,
                    dark: _vm.filterChanged
                  },
                  on: { click: _vm.updateFilter }
                },
                [_vm._v(" Update Filter ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }