var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap gap" },
    [
      _vm._l(_vm.displayFilterChoices, function(f) {
        return _c(
          "v-chip",
          {
            key: f.selectedField,
            attrs: { close: "" },
            on: {
              "click:close": function($event) {
                return _vm.$emit("remove-filter", f.selectedField)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "chip overflow-hidden text-truncate my-0 py-0" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formSubmissionFields")(f.selectedField)) +
                    " "
                ),
                _c("MatchIcon", {
                  staticClass: "mx-2",
                  attrs: { value: f.selectedMatchChoice }
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDisplayMatchValue(
                        f.selectedField,
                        f.selectedMatchValue
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          ]
        )
      }),
      _vm.refFieldFilterChoice
        ? _c(
            "v-chip",
            {
              attrs: { close: "" },
              on: {
                "click:close": function($event) {
                  return _vm.$emit(
                    "remove-filter",
                    _vm.refFieldFilterChoice.selectedField
                  )
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "chip overflow-hidden text-truncate my-0 py-0" },
                [
                  _vm._v(
                    " Reference Field = " +
                      _vm._s(_vm.refFieldFilterChoice.selectedMatchValue) +
                      " "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._l(_vm.formFieldFilterChoices, function(f) {
        return _c(
          "v-chip",
          {
            key: f.selectedFormField,
            attrs: { close: "" },
            on: {
              "click:close": function($event) {
                return _vm.$emit("remove-form-field-filter", f)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "chip overflow-hidden text-truncate my-0 py-0" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.getDisplayFormFieldTitle(f.selectedFormField)) +
                    " "
                ),
                _c("MatchIcon", {
                  staticClass: "mx-2",
                  attrs: { value: f.selectedFormFieldMatchChoice }
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDisplayFormFieldMatchValue(
                        f.selectedFormField,
                        f.selectedFormFieldMatchValue
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }